import { h, Component } from 'preact'
import Props from 'proptypes'
import classnames from 'classnames'

let ContinueButton = ({ bhw, buttonText, onUserClickedContinue, showButtonBackground, additionalClassSuffix }) => {
  var classesForButton = classnames({
    'tui-button': true,
    'tui-button-hidden': !showButtonBackground
  })
  var classesForButtonText = classnames({
    'tui-button-text': true,
    'tui-button-text-standalone': !showButtonBackground
  })

  let classesForFooter = 'tui-footer'
  if (additionalClassSuffix) {
    classesForFooter += ' ' + 'tui-footer' + additionalClassSuffix
    classesForButton += ' ' + 'tui-button' + additionalClassSuffix
    classesForButtonText += ' ' + 'tui-button-text' + additionalClassSuffix
  }

  let bloodhoundTag = 'DealPreferencesButtonNext'
  return (
    <div className={classesForFooter}>
      <div className={classesForButton} data-bhw={bloodhoundTag} onClick={onUserClickedContinue}>
        <div className={classesForButtonText}>
          <span>{buttonText}</span>
        </div>
      </div>
    </div>)
}

ContinueButton.propTypes = {
  buttonText: Props.string.isRequired,
  additionalClassSuffix: Props.string,
  onUserClickedContinue: Props.func.isRequired,
  showButtonBackground: Props.bool.isRequired
}

export default ContinueButton
