import { h } from 'preact'
import classnames from 'classnames'
import PropTypes from 'proptypes'

function renderHidden () {
  return <div className='tui-tile tui-tile-hidden' />
}

function renderNormal ({ backgroundImage, selected, onUserClickedTile, label, id, index, pagenumber}) {
  let divStyle = {
    backgroundImage: 'url(' + backgroundImage + ')'
  }
  let tuiTileClasses = classnames({
    'tui-tile': true,
    'tui-tile-selected': selected
  })
  let tuiTileHeaderClasses = classnames({
    'tui-tile-header': true,
    'tui-tile-header-selected': selected
  })
  let tuiSymbolClasses = classnames({
    'tui-symbol': true,
    'tui-symbol-selected': selected
  })
  let iconPlusNoCircleClasses = classnames({
    'icon-plus-no-circle': !selected,
    'icon-filled-check': selected
  })

  let handleClick = () => {
    onUserClickedTile(id, index, pagenumber)
  }

  let convertedLabel = label.toLowerCase().replace(/ /g, '-').replace(/&/g, 'and')
  return (
    <div
      onClick={() => handleClick()}
      className={tuiTileClasses} data-bhc={'DealType:' + convertedLabel} data-permalink={convertedLabel}>
      <div className={tuiTileHeaderClasses}>
        <div className='tui-tile-header-label'><span>{label}</span></div>
        <div className={tuiSymbolClasses}><div className={iconPlusNoCircleClasses} /></div>
      </div>
      <div style='overflow: hidden;'>
        <div className='tui-tile-background' style={divStyle} />
      </div>
    </div>)
}

let ToggleableTile = ({ hidden, backgroundImage, selected, onUserClickedTile, label, id, index, pagenumber }) => {
  if (hidden) {
    return renderHidden()
  }

  return renderNormal({ backgroundImage, selected, onUserClickedTile, label, id, index, pagenumber })
}

ToggleableTile.propTypes = {
  backgroundImage: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  index: PropTypes.number,
  selected: PropTypes.bool,
  onUserClickedTile: PropTypes.func,
  hidden: PropTypes.bool
}

export default ToggleableTile
