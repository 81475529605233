import { h, Component } from 'preact'
import classnames from 'classnames'
import PropTypes from 'proptypes'
import { createStore } from 'redux'

import reducer from '../store/reducer'
import Header from './Header'
import HeaderWithSpan from './HeaderWithSpan'
import SimpleTileList from './SimpleTileList'
import ContinueButton from './ContinueButton'
import ContinueButtonDone from './ContinueButtonDone'
import DismissButton from './DismissButton'

class AppWizard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...props
    }

    this.state.onUserClickedContinue = this.onUserClickedContinueButton.bind(this)
    if (!this.state.onUserClickedTile) {
      this.state.onUserClickedTile = this.onUserClickedTile.bind(this)
    }

    this.onUserClickedXDismissButton = this.onUserClickedXDismissButton.bind(this)

    if (this.state.override === false) {
      this.appStore = createStore(reducer, /* preloadedState, */
        typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
      this.appStore.subscribe(() => {
        this.setState(this.appStore.getState())
      })

      this.appStore.dispatch({
        type: 'INIT_APP',
        ...props
      })
    }
  }

  onUserClickedContinueButton () {
    this.appStore.dispatch({
      type: 'USER_CLICKED_CONTINUE'
    })
  }

  onUserClickedXDismissButton () {
    this.appStore.dispatch({
      type: 'USER_CLICKED_X_DISMISS_BUTTON'
    })
  }

  onUserClickedTile (id, index) {
    this.appStore.dispatch({ type: 'USER_CLICKED_TILE', id, index })
  }

  render () {
    if (this.state.isDone) {
      return this.renderDone()
    }

    return this.renderNormal()
  }

  renderDone () {
    return (
      <div className='tui-modal tui-modal-done' data-bhw='Deal Preferences Section'>
        <DismissButton onDismissFunction={this.onUserClickedXDismissButton} additionalClasses='tui-dismiss-x-done' />
        <div class='tui-done-icon'><span class='icon-check-circle' /></div>
        <HeaderWithSpan headline="Thanks!  You'll soon see more deals that match your preferences." headerClass='tui-header' additionalClasses='tui-header-done' additionalTextClasses='tui-header-text-done' />
        <HeaderWithSpan headline='Update your preferences by visiting your profile.' headerClass='tui-subheader' additionalClasses='tui-subheader-done' additionalTextClasses='tui-subheader-text-done' />
        <ContinueButtonDone
          showButtonBackground
          buttonText='continue shopping'
          additionalClassSuffix='-done'
          onUserClickedContinue={this.state.onUserClickedContinue} />
      </div>
    )
  }

  renderNormal () {
    let classes = classnames({
      'tui-modal': true
    })

    const pages = this.state.pages
    const activePageIndex = this.state.activePageIndex

    var subheaderHeadline =
      `${pages.pageInfo[activePageIndex].brandProp} ` +
      `(step ${activePageIndex - 0 + 1}/${pages.pageInfo.length})`

    return (
      <div className={classes} data-bhw='Deal Preferences Section'>
        <DismissButton onDismissFunction={this.onUserClickedXDismissButton} />
        <Header headline={pages.brandProp} headerClass='tui-header' />
        <Header headline={subheaderHeadline} headerClass='tui-subheader' />
        <SimpleTileList
          tiles={pages.pageInfo[activePageIndex].tiles}
          onUserClickedTile={this.state.onUserClickedTile}
        />
        <ContinueButton
          showButtonBackground={this.state.anyTilesSelected}
          buttonText={this.state.continueButtonText}
          onUserClickedContinue={this.state.onUserClickedContinue} />
      </div>
    )
  }
}

AppWizard.propTypes = {
  pages: PropTypes.object.isRequired,
  activePageIndex: PropTypes.number.isRequired,
  continueButtonText: PropTypes.string.isRequired,
  dispatchFunction: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  onNotifyClickedTileFunction: PropTypes.func
}

export default AppWizard
