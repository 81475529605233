import { h } from 'preact'
import habitat from 'preact-habitat'

import Widget from './components/AppWizard'

let { markSelectedPreferences } = require('./helpers/mark-selected-preferences')
let taxonomyNodes = require('./taxonomy_nodes')

let _habitat = habitat(Widget)

function showDialog (onNotifyClickedTileFunction, onCloseDialogFunction, selectedPreferences) {
  let props = {
    ...taxonomyNodes
  }

  markSelectedPreferences(selectedPreferences, props)

  if (onNotifyClickedTileFunction) {
    props.onNotifyClickedTileFunction = onNotifyClickedTileFunction
  }

  if (onCloseDialogFunction) {
    props.onCloseDialogFunction = onCloseDialogFunction
  }

  _habitat.render({
    selector: '[data-widget-host="habitat"]',
    clean: true,
    defaultProps: props
  })
}

export { showDialog }
