import { h, Component } from 'preact'
import PropTypes from 'proptypes'

let DismissButton = ({ onDismissFunction, additionalClasses }) => {
  let classes = 'tui-dismiss-x';
  if (additionalClasses) {
    classes += ' ' + additionalClasses
  }
  return (
    <div className={classes} onClick={onDismissFunction} data-bhw='DealPreferencesButtonDismiss'><span className='icon-x' /></div>
  )
}

DismissButton.propTypes = {
  onDismissFunction: PropTypes.func.isRequired,
  additionalClasses: PropTypes.string
}

export default DismissButton
