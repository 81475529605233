import { h, Component } from 'preact'
import PropTypes from 'proptypes'
import ToggleableTile from './ToggleableTile'

let SimpleTileList = ({ tiles, onUserClickedTile, pagenumber }) => {
  let toggleableTiles = tiles.map((tile, index) => {
    return <ToggleableTile
      backgroundImage={tile.backgroundImage}
      id={tile.id}
      label={tile.label}
      index={index}
      selected={tile.selected}
      onUserClickedTile={onUserClickedTile}
      pagenumber={pagenumber}
    />
  })

  return (
    <div className='tui-body'>
      <div className='tui-tiles'>
        <div className='tui-row'>
          {toggleableTiles}
          <ToggleableTile hidden />
          <ToggleableTile hidden />
          <ToggleableTile hidden />
          <ToggleableTile hidden />
        </div>
      </div>
    </div>
  )
}

SimpleTileList.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUserClickedTile: PropTypes.func.isRequired
}

export default SimpleTileList
