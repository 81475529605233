const initialState = {
  pages: undefined,
  activePageIndex: -1
}

function areAnyPagesNotObjects (pages) {
  return pages.map((element) => {
    return typeof element === 'object'
  }).some((element) => {
    return element === false
  })
}

function handleInitApp (state, action) {
  if (action.pages === undefined || (typeof action.pages !== 'object') || !Array.isArray(action.pages.pageInfo) ||
    action.pages.pageInfo.length < 1 || areAnyPagesNotObjects(action.pages.pageInfo)
  ) {
    return state
  }

  const newState = {
    ...action
  }

  if (newState.activePageIndex === undefined) {
    const defaultPageIndex = 0
    newState.activePageIndex = defaultPageIndex
  }

  newState.anyTilesSelected = getAnyTilesSelected(newState.pages.pageInfo[newState.activePageIndex].tiles)
  if (newState.continueButtonText === undefined) {
    newState.continueButtonText =
      getButtonText(newState.anyTilesSelected, newState.activePageIndex, newState.pages.pageInfo.length)
  }

  return newState
}

function getButtonText (anyTilesSelected, activePageIndex, pageInfoLength) {
  let continueButtonText = 'skip this step'
  if (anyTilesSelected) {
    const onLastPage = activePageIndex === pageInfoLength - 1
    if (onLastPage) {
      continueButtonText = 'done'
    } else {
      continueButtonText = 'next'
    }
  }
  return continueButtonText
}

function getAnyTilesSelected (tiles) {
  return tiles.some(function (tile) {
    return tile.selected === true
  })
}

function handleUserClickedContinue (state) {
  const newState = {
    ...state
  }

  if (newState.isDone) {
    if (state.onCloseDialogFunction) {
      state.onCloseDialogFunction()
    }

    return
  }

  if (newState.activePageIndex + 1 === newState.pages.pageInfo.length) {
    newState.isDone = true
    return newState
  }
  newState.activePageIndex += 1
  newState.anyTilesSelected = getAnyTilesSelected(newState.pages.pageInfo[newState.activePageIndex].tiles)
  newState.continueButtonText = getButtonText(newState.anyTilesSelected, newState.activePageIndex, newState.pages.pageInfo.length)
  return newState
}

function handleUserClickedTile (state, action) {
  const newState = {
    ...state
  }

  const { pages, activePageIndex } = newState
  const { pageInfo } = pages
  const currentPage = pageInfo[activePageIndex]
  const clickedTile = currentPage.tiles[action.index]
  if (clickedTile.selected !== undefined) {
    clickedTile.selected = !clickedTile.selected
  } else {
    clickedTile.selected = true
  }

  newState.anyTilesSelected =
    getAnyTilesSelected(
      newState.pages.pageInfo[newState.activePageIndex].tiles)

  newState.continueButtonText =
    getButtonText(newState.anyTilesSelected,
      newState.activePageIndex, newState.pages.pageInfo.length)

  if (newState.onNotifyClickedTileFunction) {
    newState.onNotifyClickedTileFunction(clickedTile.id, clickedTile.label.replace(/\s+/g, '-').toLowerCase(), clickedTile.selected === true ? 'positive' : 'negative')
  }

  return newState
}

function handleUserClickedTilePagenum (state, action) {
  const newState = {
    ...state
  }

  const { pages } = newState
  const { pageInfo } = pages
  const currentPage = pageInfo[action.pagenumber]
  const clickedTile = currentPage.tiles[action.index]
  if (clickedTile.selected !== undefined) {
    clickedTile.selected = !clickedTile.selected
  } else {
    clickedTile.selected = true
  }

  if (newState.onNotifyClickedTileFunction) {
    newState.onNotifyClickedTileFunction(action.id, clickedTile.label.replace(/\s+/g, '-').toLowerCase(), clickedTile.selected === true ? 'positive' : 'negative')
  }

  return newState
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_APP':
      return handleInitApp(state, action)

    case 'USER_CLICKED_CONTINUE':
      return handleUserClickedContinue(state)

    case 'USER_CLICKED_TILE':
      return handleUserClickedTile(state, action)

    case 'USER_CLICKED_TILE_PAGENUM':
      return handleUserClickedTilePagenum(state, action)

    case 'USER_CLICKED_X_DISMISS_BUTTON':
      if (state.onCloseDialogFunction) {
        state.onCloseDialogFunction()
      }
      return

    default:
      return state
  }
}
