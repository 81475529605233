import { h, Component } from 'preact'
import PropTypes from 'proptypes';

let Header = ({ headerClass, headline, additionalClasses, additionalTextClasses}) => {
  let tuiHeaderClass = headerClass
  let tuiHeaderTextClass = headerClass + '-text'

  if (additionalClasses) {
    tuiHeaderClass += ' ' + additionalClasses
  }

  if (additionalTextClasses) {
    tuiHeaderTextClass += ' ' + additionalTextClasses
  }

  return (
    <div className={tuiHeaderClass}>
      <div className={tuiHeaderTextClass}>{headline}</div>
    </div>
  )
}

Header.propTypes = {
  headerClass: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired
}

export default Header
