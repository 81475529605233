module.exports = {
  override: false,
  pages: {
    brandProp: 'Category Preferences',
    pageInfo: [
      {
        brandProp: 'Local activities and services',
        tiles: [
          {
            label: 'Classes',
            id: '53728dde-0eac-41b9-9126-dc23f81836c8',
            backgroundImage: 'https://img.grouponcdn.com/test/FdvuMfVhxsEwnzu7dm3MkFzWxRd/Fd-386x254',
            selected: false
          },
          {
            label: 'Concerts & Live Events',
            id: 'ec56553b-04fb-464d-bb60-f5870285c84a',
            backgroundImage: 'https://img.grouponcdn.com/test/3aax3ffC5jk4hysmBhNmVSVVfbBb/3a-386x254',
            selected: false
          },
          {
            label: 'Group Activities',
            id: 'a81c4d6b-74c8-4f76-a2f9-954490305759',
            backgroundImage: 'https://img.grouponcdn.com/test/2ALw1NHpX9otknczn5LjTNMpxkVU/2A-386x254',
            selected: false
          },
          {
            label: 'Kids’ activities',
            id: 'db8b5a58-4cf5-429c-8317-126dd4b5fe09',
            backgroundImage: 'https://img.grouponcdn.com/test/E3756aT95GhyPEkiCQ8sS6roatZ/E3-386x254',
            selected: false
          },
          {
            label: 'Sightseeing',
            id: '31e17d56-9ec7-47da-bf3c-a48185af98d3',
            backgroundImage: 'https://img.grouponcdn.com/test/2TFNnqJYK8gTgNMutmvZ95X6MzNG/2T-386x254',
            selected: false
          },
          {
            label: 'Outdoor Activities',
            id: 'c541512c-547c-4bf0-a2f6-7c999422bb78',
            backgroundImage: 'https://img.grouponcdn.com/test/2Vpq7EvyZTNiR1LucPSYiaWKVfk1/2V-386x254',
            selected: false
          },
          {
            label: 'Date Night',
            id: '49d9b259-68ef-4f19-b374-384ad2cc6fd5',
            backgroundImage: 'https://img.grouponcdn.com/test/2eDXH1ytsjNk6dNQr4oKUQyUyttA/2e-386x254',
            selected: false
          },
          {
            label: 'Sports',
            id: '8cce0b31-dc97-4f65-8356-ce23d21b2d08',
            backgroundImage: 'https://img.grouponcdn.com/test/3r1uCRPRWesPUcmU5pL2ShsUiTEA/3r-386x254',
            selected: false
          }
        ]
      },
      {
        brandProp: 'Beauty & Spas',
        tiles: [
          {
            label: 'Hair & Styling',
            id: '0d2ccc22-094a-49d9-b4a2-fe8a698c6792',
            backgroundImage: 'https://img.grouponcdn.com/test/4HTg6CBctRKZF1ag5VkdBjtdF3kA/4H-1200x900',
            selected: false
          },
          {
            label: 'Brows & Lashes',
            id: '0477f321-9726-4ee7-9d5c-a5c1f3364f49',
            backgroundImage: 'https://img.grouponcdn.com/asset/4MUmGw4yFR85rq6t5ZttQpN9HAQy/4M-193x127',
            selected: false
          },
          {
            label: 'Cosmetic Procedures',
            id: '0e985c9a-e563-4ccb-94bd-c3765e6c9c43',
            backgroundImage: 'https://img.grouponcdn.com/asset/3cpb5bFCXQN5uSr21GyUxpQKMt1K/3c-386x254',
            selected: false
          },
          {
            label: 'Hair Removal',
            id: 'abadf303-563a-43d4-ba11-f3cf30f33d55',
            backgroundImage: 'https://img.grouponcdn.com/asset/3G3PVLUTdBsiPHRqaT9vDSELHUdn/3G-386x254',
            selected: false
          },
          {
            label: 'Spa',
            id: 'e0f889b2-8cee-493f-aa10-a67850347f0a',
            backgroundImage: 'https://img.grouponcdn.com/asset/2zBjXJiQ6D4pYZc6u17nCegoCHGp/2z-386x254',
            selected: false
          },
          {
            label: 'Face & Skin Care',
            id: '43108f4f-d409-4781-aa3e-ddca8887fd37',
            backgroundImage: 'https://img.grouponcdn.com/asset/36jG9iP4b7eUq6Ydrc5mk7A45K37/36-386x254',
            selected: false
          },
          {
            label: 'Makeup',
            id: '5b433e06-e998-4880-a971-2a6cfbaa149c',
            backgroundImage: 'https://img.grouponcdn.com/asset/4GiTURNoWvoG49yKDq7hCCtY3nto/4G-386x254',
            selected: false
          },
          {
            label: 'Nail Salons',
            id: '712cb4e2-6040-4fd6-bc8b-52d0fbb9b715',
            backgroundImage: 'https://img.grouponcdn.com/asset/47t9crG48Wt7sanboCtHZshQCZBb/47-386x254',
            selected: false
          },
          {
            label: 'Tanning',
            id: 'ddde834d-188e-4feb-bbab-2d2209b1f5c6',
            backgroundImage: 'https://img.grouponcdn.com/asset/25UCq9653G8uitx9Ybn2PGqxLHfB/25-386x254',
            selected: false
          }
        ]
      },
      {
        brandProp: 'Food & Drink',
        tiles: [
          {
            label: 'Bars',
            id: 'ee2294e5-51eb-4183-a9a8-e0b89953a22e',
            backgroundImage: 'https://img.grouponcdn.com/asset/3u27pZ1PpyaY7KYHP7MhLMd9MeQ2/3u-386x254',
            selected: false
          },
          {
            label: 'Cafes & Treats',
            id: '0e6b04a4-35cf-435e-93e0-450cd634ac22',
            backgroundImage: 'https://img.grouponcdn.com/asset/2uVCUDPb88GLR7g2fzJfCNWFnqu3/2u-386x254',
            selected: false
          },
          {
            label: 'Mexican Restaurants',
            id: '316ea6c3-37ce-4fc1-a2da-6689ade03e8d',
            backgroundImage: 'https://img.grouponcdn.com/asset/TEKitFnoBKxJZEKoov2HEQ9AJHN/TE-386x254',
            selected: false
          },
          {
            label: 'American Restaurants',
            id: 'b2d70cb1-a366-46d5-9220-0c3f55584575',
            backgroundImage: 'https://img.grouponcdn.com/asset/iw7NR7gyGzJiZTFNz258neLxqa3/iw-386x254',
            selected: false
          },
          {
            label: 'Italian Restaurants',
            id: '387f23dd-c168-4811-97f4-ecee1e9a8c9b',
            backgroundImage: 'https://img.grouponcdn.com/asset/FD829SvVExVBPGJjAxLPjYzLWQP/FD-386x254',
            selected: false
          },
          {
            label: 'Indian Restaurants',
            id: 'b011d530-df6b-4099-8ccb-5df9fd4b20d0',
            backgroundImage: 'https://img.grouponcdn.com/asset/TuH8YVeCSJZPKmJET3bWKjWYYdm/Tu-386x254',
            selected: false
          },
          {
            label: 'Mediterranean Restaurants',
            id: 'eb98e0fe-f73a-4eab-ada0-cdb809c3421a',
            backgroundImage: 'https://img.grouponcdn.com/test/2is3zH4oYNCxt3QyTLFwY82TkDvc/2i-386x254',
            selected: false
          },
          {
            label: 'Japanese Restaurants',
            id: '235ce3b3-cce5-4f0e-9ffc-77f66aff7c51',
            backgroundImage: 'https://img.grouponcdn.com/asset/YtXjy76tKhXNii4krCq8hhiBgKF/Yt-386x254',
            selected: false
          },
          {
            label: 'Chinese Restaurants',
            id: '26b55f22-eb03-444b-9568-5095d21b7443',
            backgroundImage: 'https://img.grouponcdn.com/asset/rsdTinELeQqnZ5e1XZZj2cV9PXH/rs-386x254',
            selected: false
          },
          {
            label: 'Thai Restaurants',
            id: '4f630dff-be7c-40ba-81e0-8c626e3a4b59',
            backgroundImage: 'https://img.grouponcdn.com/asset/iw7NR7gyGzJiZTFNz258neLxqa3/iw-386x254',
            selected: false
          },
          {
            label: 'Korean Restaurants',
            id: '5991af1b-ecea-4ab4-857b-36d361c06551',
            backgroundImage: 'https://img.grouponcdn.com/asset/FsiUED1Z9bmJsU3dL3qAVaYLZmy/Fs-386x254',
            selected: false
          },
          {
            label: 'Asian Restaurants',
            id: '0ddc1f8e-d1ae-4fbe-8329-0625994d0234',
            backgroundImage: 'https://img.grouponcdn.com/test/4Qh212U5bTbDLaxhw1diz1q9pqko/4Q-1200x900',
            selected: false
          }
        ]
      }
    ]
  }
}
