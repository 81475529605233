const showDialog = require('@grpn/taxonomy-ui/dist/lib/showDialog').showDialog;
const setItem = require('./local_storage').setItem;
const taxonomy = require('./taxonomy_preferences');

module.exports = {
  showTaxonomyDialog(GIGModal, onUserClickedTaxonomyTile, selectedPreference) {
    showDialog(
      onUserClickedTaxonomyTile.bind(this),
      () => GIGModal.close(),
      selectedPreference
    );
    GIGModal.open({
      id: 'taxonomy-preferences-modal',
    });
    setItem('shownTaxonomyPreferences', 'true');
  },
  onUserClickedTaxonomyTile(id, label, positiveOrNegative) {
    const fetchParam = {
      id,
      value: positiveOrNegative,
      _csrf: this.domConfig.csrfToken ? this.domConfig.csrfToken : void 0,
    };
    return taxonomy.save(fetchParam);
  },
};
