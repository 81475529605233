'use strict'

module.exports.markSelectedPreferences = function (selectedPreferences, { pages }) {
  if (selectedPreferences && selectedPreferences.length) {
    let propPreference = {}
    pages.pageInfo.forEach(page => {
      page.tiles.forEach(taxonomy => {
        propPreference[taxonomy.id] = taxonomy
      })
    })

    selectedPreferences.forEach(preference => {
      if (propPreference[preference.preferenceId]) {
        propPreference[preference.preferenceId].selected = true
      }
    })
  }
}
