import { h, Component } from 'preact'
import PropTypes from 'proptypes';

let HeaderWithSpan = ({ headerClass, headline, additionalClasses, additionalTextClasses }) => {
  let tuiHeaderClass = headerClass
  let tuiHeaderTextClass = headerClass + '-text'

  if (additionalClasses) {
    tuiHeaderClass += ' ' + additionalClasses
  }

  if (additionalTextClasses) {
    tuiHeaderTextClass += ' ' + additionalTextClasses
  }

  return (
    <div className={tuiHeaderClass}>
      <span>{headline}</span>
    </div>
  )
}

HeaderWithSpan.propTypes = {
  headerClass: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired
}

export default HeaderWithSpan
